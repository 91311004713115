@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* 3D Effects */
@layer utilities {
  .perspective-1000 {
    perspective: 1000px;
  }

  .rotate-y-12 {
    transform: rotateY(12deg);
  }

  .rotate-y-16 {
    transform: rotateY(16deg);
  }

  .rotate-x-2 {
    transform: rotateX(2deg);
  }

  .translate-z-10 {
    transform: translateZ(10px);
  }

  .translate-z-20 {
    transform: translateZ(20px);
  }
}
